import axios from 'axios';
import moment from 'moment';
import { Badge,message } from 'antd';
import { BASE_URL, ORDER_STATUS } from './constants';

const instance = axios.create({ baseURL: BASE_URL });
const request = method => async (url, data) => {
  const obj = {
    method,
    url
  };

  if (data) {
    if (method === 'get') {
      obj.params = data;
    } else {
      obj.data = data;
    }
  }

  let resp = null;
  try {
    resp = await instance.request(obj);
  } catch (err) {
    if (err.response.status < 500) {
      throw err;
    } else {
      // console.log(`[请求失败] ${err.message}`);
      message.error('请稍后再试');
    }
  }

  return resp;
};

export const _get = request('get');
export const _patch = request('patch');
export const _post = request('post');
export const _delete = request('delete');
export const _put = request('put');

export const _formatDate = value => {
  const mValue = moment(value);
  if (mValue.isValid()) {
    return mValue.format('YYYY年MM月DD日 MM:HH:ss');
  }

  return 'n/a'
}

export const _formatStatus = value => {
  const status = ORDER_STATUS[value];
  if (status) {
    return <Badge status={status.value} text={status.label} />;
  }

  return <Badge status="default" text="n/a" />;
};

export const _evaluate = (value, refs) => {
  if (value >= refs[0]) {
    return '优秀';
  }
  if (value >= refs[1]) {
    return '良好';
  }
  if (value >= refs[2]) {
    return '一般';
  }
  if (value >= refs[3]) {
    return '较低';
  }
  if (value >= refs[4]) {
    return '较差';
  }
}