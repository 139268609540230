export const BASE_URL = '/api';

export const VERSION = '1.0';

export const ROLE = {
  user: 'user',
  assessor: 'assessor'
};

export const STATUS = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  failure: 'failure'
};

export const ORDER_STATUS = {
  success: { value: 'success', label: '已完成' },
  processing: { value: 'processing', label: '进行中' },
  error: { value:'error', label: '未完成' }
};

export const ORDER_INDUSTRIES = [
  { label: '餐饮业', value: 0 },
  { label: '纺织服装服饰业', value: 1 },
  { label: '工艺品及其他制造业', value: 2 },
  { label: '金属制品业', value: 3 },
  { label: '毛纺织业', value: 4 },
  { label: '棉化纤纺织业', value: 5 },
  { label: '农业', value: 6 },
  { label: '文化体育用品批发与零售', value: 7 },
  { label: '医药及医疗器材批发与零售', value: 8 },
  { label: '住宿业', value: 9 },
  { label: '汽车制造业', value: 10 },
  { label: '家用电器制造业', value: 11 },
  { label: '信息技术服务业', value: 12 },
  { label: '酒和饮料制造业', value: 13 },
  { label: '房地产业', value: 14 },
  { label: '传播与文化业', value: 15 },
  { label: '通用设备制造业', value: 16 },
  { label: '其他', value: 17 },
];