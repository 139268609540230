import styles from './Footer.module.css';

function Footer() {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.title}>如有疑问，欢迎咨询</div>
        <div className={styles.hotline}>
          咨询电话
          <br />
          <a href="tel:4000062018">400-006-2018</a>
          <br />
          工作日9:30至18:00
        </div>
        {/* <div className={styles.qrcode}>
          <img src="/qrcode.jpg" alt="微信公众号二维码" />
          <br />
          微信公众号
        </div> */}
        <div className={styles.copyright}>由<a href="https://www.biaoxq.com">环球互通品牌服务有限公司</a>提供技术支持</div>
      </div>
    </div>
  );
}

export default Footer;