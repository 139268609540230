import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import styles from "./Root.module.css";
import Footer from "../m-components/Footer";

import { STATUS } from "../constants";
import { _get } from "../utils";

function Root() {
  const [status, setStatus] = useState(STATUS.idle);
  const navigate = useNavigate();
  // const [user, setUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const doGet = async () => {
      try {
        setStatus(STATUS.loading);
        const resp = await _get("/sessions");
        if (resp) {
          // setUser(resp.data.payload);
          setStatus(STATUS.success);
        }
      } catch (err) {
        navigate("/m/login", { replace: true, state: { referral: location } });
      }
    };
    if (status === STATUS.idle) {
      doGet();
    }
  }, [status, location, navigate]);

  return (
    <div className={styles.root}>
      <Outlet />
      <Footer />
    </div>
  );
}

export default Root;
