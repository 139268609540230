import styles from "./Report.module.css";
import Header from "../m-components/Header";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { round } from "lodash";

import { STATUS, ORDER_INDUSTRIES } from "../constants";
import { _get, _evaluate } from "../utils";
import { useParams } from "react-router-dom";
import { Button, Spin } from "antd";

function Report() {
  const [status, setStatus] = useState(STATUS.idle);
  const [data, setData] = useState(null);
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const year = data?.createdAt.slice(0, 4);
  useEffect(() => {
    const doGet = async () => {
      try {
        setStatus(STATUS.loading);
        const resp = await _get(`/orders/${orderId}`);
        setData(resp.data.payload);

        setStatus(STATUS.success);
      } catch (err) {}
    };
    if (status === STATUS.idle) {
      doGet();
    }
  }, [status, orderId]);

  return (
    <>
      <Header
        title="评估简报"
        subTitle="商标品牌价值评估模型（试用）"
        showBackBtn
      />
      {status === STATUS.success ? (
        <div className={styles.root}>
          <h2>一、品牌简介</h2>
          <p>{`“${data.brand}”是${
            data.enterprise
          }旗下品牌，企业主营业务所属行业为“${
            ORDER_INDUSTRIES[data.industry].label
          }”。`}</p>
          <h2>二、财务数据</h2>
          <p>{`“${data.brand}”品牌具体财务数据如下：`}</p>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{year - 3}年</th>
                <th>{year - 2}年</th>
                <th>{year - 1}年</th>
                <th>{year}年预测</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>收入</td>
                <td>{data.finance.y3[0]}万元</td>
                <td>{data.finance.y2[0]}万元</td>
                <td>{data.finance.y1[0]}万元</td>
                <td>{data.finance.y0[0]}万元</td>
              </tr>
              <tr>
                <td>成本</td>
                <td>{data.finance.y3[1]}万元</td>
                <td>{data.finance.y2[1]}万元</td>
                <td>{data.finance.y1[1]}万元</td>
                <td>{data.finance.y0[1]}万元</td>
              </tr>
              <tr>
                <td>利润</td>
                <td>{data.finance.y3[2]}万元</td>
                <td>{data.finance.y2[2]}万元</td>
                <td>{data.finance.y1[2]}万元</td>
                <td>{data.finance.y0[2]}万元</td>
              </tr>
              <tr>
                <td>净利润</td>
                <td>{data.finance.y3[3]}万元</td>
                <td>{data.finance.y2[3]}万元</td>
                <td>{data.finance.y1[3]}万元</td>
                <td>{data.finance.y0[3]}万元</td>
              </tr>
              <tr>
                <td>授权收入</td>
                <td>{data.finance.y3[8]}万元</td>
                <td>{data.finance.y2[8]}万元</td>
                <td>{data.finance.y1[8]}万元</td>
                <td>{data.finance.y0[8]}万元</td>
              </tr>
            </tbody>
          </table>
          <p>{`经参考同行业相关数据，“${data.brand}”品牌经营业绩评估如下：`}</p>
          <table style={{ width: 800 }}>
            <thead>
              <tr>
                <th></th>
                <th>自身</th>
                <th>行业优秀</th>
                <th>行业良好</th>
                <th>行业平均</th>
                <th>行业较低</th>
                <th>行业较差</th>
                <th>判断</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>销售利润率</td>
                <td>{round(data.s[0] * 100, 2)}%</td>
                <td>{round(data.r[0][0] * 100, 2)}%</td>
                <td>{round(data.r[0][1] * 100, 2)}%</td>
                <td>{round(data.r[0][2] * 100, 2)}%</td>
                <td>{round(data.r[0][3] * 100, 2)}%</td>
                <td>{round(data.r[0][4] * 100, 2)}%</td>
                <td>{_evaluate(data.s[0], data.r[0])}</td>
              </tr>
              <tr>
                <td>销售增长率</td>
                <td>{round(data.s[1] * 100, 2)}%</td>
                <td>{round(data.r[1][0] * 100, 2)}%</td>
                <td>{round(data.r[1][1] * 100, 2)}%</td>
                <td>{round(data.r[1][2] * 100, 2)}%</td>
                <td>{round(data.r[1][3] * 100, 2)}%</td>
                <td>{round(data.r[1][4] * 100, 2)}%</td>
                <td>{_evaluate(data.s[1], data.r[1])}</td>
              </tr>
              <tr>
                <td>销售利润增长率</td>
                <td>{round(data.s[2] * 100, 2)}%</td>
                <td>{round(data.r[2][0] * 100, 2)}%</td>
                <td>{round(data.r[2][1] * 100, 2)}%</td>
                <td>{round(data.r[2][2] * 100, 2)}%</td>
                <td>{round(data.r[2][3] * 100, 2)}%</td>
                <td>{round(data.r[2][4] * 100, 2)}%</td>
                <td>{_evaluate(data.s[2], data.r[2])}</td>
              </tr>
            </tbody>
          </table>
          <h2>三、模型参数</h2>
          <table style={{ width: 720 }}>
            <thead>
              <tr>
                <th>年税后利润基数</th>
                <th>利润倍数</th>
                <th>授权费倍数</th>
                <th>品牌贡献率</th>
                <th>年授权费净收入</th>
                <th>因素修正</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data.t[0]}万元</td>
                <td>{round(data.t[2], 2)}</td>
                <td>{round(data.t[4], 2)}</td>
                <td>{data.assessor[0]}%</td>
                <td>{data.t[3]}万元</td>
                <td>{round(data.t[7] * 100, 2)}%</td>
              </tr>
            </tbody>
          </table>
          <h2>四、估值结论</h2>
          <ul>
            <li>{`估值低值 = 自用价值 + 授权价值 = ${data.t[8]}万元（取整）`}</li>
            <li>
              {`估值高值 = (自用价值 + 授权价值) * 因素修正 = ${data.t[9]}万元（取整）`}
            </li>
            <li>
              最终估值区间为 <strong>{data.t[8]}万元</strong> 至{" "}
              <strong>{data.t[9]}万元</strong>
            </li>
          </ul>
          <p style={{ marginTop: "2rem" }}>
            <Button
              onClick={() =>
                navigate("/m/orders", { state: { referral: location } })
              }
              type="primary"
              size="large"
              block
            >
              返回评估记录
            </Button>
          </p>
        </div>
      ) : (
        <div className={styles.loading}>
          <Spin />
        </div>
      )}
    </>
  );
}

export default Report;
