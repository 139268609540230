import {
  PlusOutlined,
  LogoutOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
  PoweroffOutlined
} from "@ant-design/icons";
import { Button, Divider, Drawer } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./Header.module.css";

function Header({
  title,
  subTitle,
  showBackBtn,
  showMenuBtn,
  showAddBtn,
  showInfoBtn,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className={styles.root}>
        <div className={styles.left}>
          {showMenuBtn ? (
            <div onClick={() => setOpen(true)} className={styles.button}>
              <PoweroffOutlined />
            </div>
          ) : null}
          {showBackBtn ? (
            <div
              onClick={() =>
                navigate("/m/orders", { state: { referral: location } })
              }
              className={styles.button}
            >
              <LeftOutlined />
            </div>
          ) : null}
        </div>
        <div className={styles.center}>
          <div className={styles.primary}>{title}</div>
          <div className={styles.secondary}>{subTitle}</div>
        </div>
        <div className={styles.right}>
          { showAddBtn ? (
            <div
              onClick={() => navigate("/m/orders/new")}
              className={styles.button}
            >
              <PlusOutlined />
            </div>
          ) : showInfoBtn ? (
            <div
              onClick={showInfoBtn}
              className={styles.button}
            >
              <QuestionCircleOutlined />
            </div>
          ) : null }
        </div>
      </div>
      <Drawer
        placement="bottom"
        open={open}
        closable={false}
        bodyStyle={{ padding: 0 }}
        onClose={() => setOpen(false)}
        height="auto"
      >
        <div className={styles.action}>
          <Button
            onClick={() => navigate("/m/logout")}
            type="primary"
            size="large"
            icon={<LogoutOutlined />}
            danger
            block
          >
            退出登录
          </Button>
          <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
          <Button size="large" onClick={() => setOpen(false)} block>取消</Button>
        </div>
      </Drawer>
    </>
  );
}

export default Header;
