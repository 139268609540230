import { useState, useEffect } from "react";
import { STATUS } from "../constants";
import { PlusOutlined, RightOutlined } from "@ant-design/icons";

import { Avatar, Modal, Button, Spin, Empty } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { _get, _formatDate, _formatStatus } from "../utils";

import styles from "./Orders.module.css";
import Header from "../m-components/Header";

function Orders() {
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS.idle);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const doIs = () => {
    if (
      location.state?.referral.pathname.split("/")[2] !== "reports" &&
      location.state?.referral.pathname.split("/")[2] !== "orders"
    ) {
      setOpen(true);
    }
  };
  useEffect(doIs, [location]);

  useEffect(() => {
    const doGet = async () => {
      try {
        setStatus(STATUS.loading);
        const resp = await _get("/orders");
        setData(resp.data.payload);
        setStatus(STATUS.success);
      } catch (err) {}
    };
    if (status === STATUS.idle) {
      doGet();
    }
  }, [status]);
  // useEffect(openNotification, [isShowNotifi]);
  return (
    <>
      <Header
        title="评估记录"
        subTitle="商标品牌价值评估模型（试用）"
        showMenuBtn
        showAddBtn
        showNotifiBtn={() => setOpen(true)}
      />
      {status === STATUS.success ? (
        <div className={styles.root}>
          {data.length > 0 ? (
            <div className={styles.list}>
              {data.map((finance, index) => {
                return (
                  <div
                    className={styles.item}
                    onClick={() => navigate(`/m/reports/${finance._id}`)}
                    key={index}
                  >
                    <div className={styles.head}>
                      <Avatar />
                    </div>
                    <div className={styles.body}>
                      <div className={styles.left}>
                        <div className={styles.primary}>{finance.brand}</div>
                        <div className={styles.secondary}>
                          {_formatDate(finance.createdAt)}
                        </div>
                      </div>
                      <div className={styles.right}>
                        {_formatStatus(finance.status)}
                        <div className={styles.indicator}>
                          <RightOutlined />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div style={{ margin: "1rem" }}>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => navigate("/m/orders/new")}
                  size="large"
                  type="primary"
                  block
                >
                  继续评估
                </Button>
              </div>
            </div>
          ) : (
            <div className={styles.empty}>
              <Empty description="没有评估记录">
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => navigate("/m/orders/new")}
                  size="large"
                  type="primary"
                  block
                >
                  立即评估
                </Button>
              </Empty>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.loading}>
          <Spin />
        </div>
      )}
      <Modal
        title="重要提示"
        open={open}
        footer={
          <Button
            onClick={() => setOpen(false)}
            size="large"
            type="primary"
            block
          >
            已阅读并知晓提示内容
          </Button>
        }
        closable={false}
      >
        <p>
          《商标品牌价值评估模型（试用）》为上海市知识产权局委托上海东方品牌文化发展促进中心协同环球互通品牌服务平台承接的课题项目成果。
        </p>
        <p>
          《商标品牌价值评估模型（试用）》只用于商标品牌所有人对自有商标品牌价值进行初步自我评估，或对其他相关群体了解该商标品牌的大致价值范围提供借鉴参考。
        </p>
        <p>
          如商标品牌价值评估结论需应用于更正式或更专业的经济行为（如商标质押或商标作价入股），建议应选择专业评估机构进行更为细致深入的详细评估。
        </p>
      </Modal>
    </>
  );
}

export default Orders;
