import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Spin } from "antd";

import styles from './Logout.module.css';
import { STATUS } from "../constants";
import { _delete } from '../utils';

function Logout() {
  const [ status, setStatus ] = useState(STATUS.idle);
  const navigate = useNavigate();

  useEffect(() => {
    const doDelete = async () => {
      setStatus(STATUS.loading);
      await _delete('/sessions');
      navigate('/m/login', { replace: true });
    };

    if (status === STATUS.idle) {
      doDelete();
    }
  }, [ status, navigate ]);
  
  return (
    <div className={styles.loading}>
      <Spin />
    </div>
  );
}

export default Logout;