import { Form, Input, Button, message, Result } from "antd";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Register.module.css";
import Footer from "../m-components/Footer";
import { STATUS } from "../constants";
import { useState } from "react";
import { _post, _patch } from "../utils";
function Register() {
  const navigate = useNavigate();
  // 状态
  const [status, setStatus] = useState(STATUS.idle);
  // phone 的值
  const [userPhone, setUserPhone] = useState(null);
  // 验证码发送标识
  let [sendMessageIndex, setSendMessageIndex] = useState(true);
  // 定义倒计时最大时长
  // let maxTime = 60
  let [maxTime, setMaxTime] = useState(60);
  const [loginValue, setLoginValue] = useState(null);

  const onFinish = (values) => {
    const doPost = async () => {
      try {
        setStatus(STATUS.loading);
        await _post("/sessions", Object.assign({}, values, { role: "user" }));
        setLoginValue({ username: values.username, password: values.password });
        setStatus(STATUS.success);
      } catch (error) {
        if (error.response.status === 403) {
          message.info(error.response.data.message);
        }
        setStatus(STATUS.failure);
      }
    };
    if (values.password !== values.confirmPassword) {
      message.info("两次密码输入不相同，请重新输入");
    } else {
      Reflect.deleteProperty(values, "confirmPassword");
      doPost();
    }
  };
  // denglu
  const doPath = async (loginValue) => {
    try {
      setStatus(STATUS.loading);
      const resp = await _patch("/sessions", {
        username: loginValue.username,
        password: loginValue.password,
      });
      if (resp) {
        navigate("/");
      }
    } catch (err) {
      setStatus(STATUS.failure);
    }
  };
  // 注册成功后的去登陆页面
  const successResult = () => {
    return (
      <Result
        status="success"
        title="成功"
        subTitle="已注册成为用户，可以登录了"
        extra={[
          <Button
            size="large"
            type="primary"
            key="console"
            onClick={() => doPath(loginValue)}
            block
          >
            立即登录
          </Button>,
        ]}
      />
    );
  };

  // 存手机号码
  const savePhone = (e) => {
    setUserPhone(e.target.value);
  };

  // 发送验证码的请求逻辑
  const sendMessage = async () => {
    const regPhone = new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g");
    if (regPhone.test(userPhone)) {
      await _post("/sms", { username: userPhone });
      setSendMessageIndex(false);
      sendMessageTimer();
    } else {
      message.info("手机号错误");
    }
  };
  // 倒计时
  const sendMessageTimer = () => {
    let messageTimer = setInterval(() => {
      if (maxTime > 0) {
        setMaxTime(--maxTime);
      } else {
        clearInterval(messageTimer);
        setSendMessageIndex(true);
        setMaxTime(60);
      }
    }, 1000);
  };

  return (
    <>
      <div className={styles.root}>
        {status === STATUS.success ? (
          successResult()
        ) : (
          <>
            <div className={styles.header}>
              <img src="/logo2.png" alt="logo" />
              <div className={styles.title}>商标品牌价值评估模型<br />（试用）</div>
            </div>
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item
                label="手机"
                name="username"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g"),
                    message: "手机号格式错误",
                  },
                ]}
              >
                <Input
                  size="large"
                  type="tel"
                  onChange={(e) => savePhone(e)}
                  placeholder="手机号码"
                />
              </Form.Item>
              <Form.Item label="短信验证码" required>
                <div className={styles.row}>
                  <Form.Item
                    name="message"
                    rules={[{ required: true, message: "请输入短信验证码" }]}
                  >
                    <Input size="large" placeholder="短信验证码" />
                  </Form.Item>
                  {sendMessageIndex ? (
                    <Button size="large" type="primary" onClick={sendMessage}>
                      发送验证码
                    </Button>
                  ) : (
                    <Button size="large" disabled type="primary">
                      重新发送{maxTime}
                    </Button>
                  )}
                </div>
              </Form.Item>
              <Form.Item
                label="姓名"
                name="nickname"
                rules={[
                  {
                    required: true,
                    message: "请输入姓名",
                  },
                ]}
              >
                <Input size="large" placeholder="姓名" />
              </Form.Item>
              <div className={styles.divider} />
              <Form.Item
                label="密码"
                name="password"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/,
                      "g"
                    ),
                    message:
                      "密码格式错误，最少6位，包括至少1个大写字母，1个小写字母，1个数字",
                  },
                ]}
              >
                <Input.Password size="large" placeholder="密码" />
              </Form.Item>
              <Form.Item
                label="确认密码"
                name="confirmPassword"
                rules={[{ required: true, message: "请输入密码" }]}
              >
                <Input.Password size="large" placeholder="确认密码" />
              </Form.Item>
              <div className={styles.item}>
                <div>
                  <Link to="/m/login">我有账号了，直接登录</Link>
                </div>
              </div>
              <Form.Item>
                <Button htmlType="submit" type="primary" size="large" block>
                  立即注册
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Register;
