import { useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useNavigate, useLocation } from "react-router-dom";

import styles from "./Login.module.css";
import Footer from "../m-components/Footer";

import { STATUS } from "../constants";
import { _patch } from "../utils";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  const [status, setStatus] = useState(STATUS.idle);

  const onFinish = (values) => {
    const doPath = async () => {
      try {
        setStatus(STATUS.loading);
        const resp = await _patch("/sessions", values);
        if (resp) {
          const referral = location.state?.referral;
          if (referral) {
            navigate(`${referral.pathname}${referral.search}${referral.hash}`);
          } else {
            navigate("/");
          }
        }
        setStatus(STATUS.success)
      } catch (err) {
        setStatus(STATUS.failure);
      }
    };
    doPath();
  };
  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <img src="/logo2.png" alt="logo" />
          <div className={styles.title}>商标品牌价值评估模型<br />（试用）</div>
        </div>
        {
          status === STATUS.failure? (
            <Alert
              style={{ marginBottom: "1rem" }}
              type="error"
              message="啊呀呀"
              description="用户名或密码不正确"
              showIcon
            />
          ):null
        }
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: "请输入手机号码" }]}
          >
            <Input size="large" prefix={<UserOutlined />} placeholder="手机" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "请输入密码" }]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="密码"
            />
          </Form.Item>
          <div className={styles.item}>
            <div className={styles.links}>
              <Link to="/m/register">我还没有账号，需要注册</Link>
              <Link to="/m/password">找回密码</Link>
            </div>
          </div>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block>
              立即登录
            </Button>
          </Form.Item>
        </Form>
        
      </div>
      <Footer />
    </>
  );
}

export default Login;
