import zh_CN  from 'antd/es/locale/zh_CN';

import { ConfigProvider } from 'antd';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// import Login from './pages/Login';
// import Logout from './pages/Logout';
// import Register from './pages/Register'
// import Root from './pages/Root';
// import Orders from './pages/Orders';
// import Order from './pages/Order';
// import Report from './pages/Report';
// import PasswordReset from './pages/PasswordReset'

import MLogin from './m-pages/Login';
import MLogout from './m-pages/Logout';
import MRegister from './m-pages/Register';
import MPassword from './m-pages/Password'
import MRoot from './m-pages/Root';
import MOrders from './m-pages/Orders';
import MOrder from './m-pages/Order';
import MReport from './m-pages/Report';

function App() {
  return (
    <ConfigProvider locale={zh_CN}>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<Root />}>
            <Route index element={<Navigate to="/orders" />} />
            <Route path="orders" element={<Orders />} />
            <Route path="orders/:orderId" element={<Order />} />
            <Route path="reports/:orderId" element={<Report />} />
          </Route> */}
          {/* 适配移动端的页面 */}
          <Route path="/m/login" element={<MLogin />} />
          <Route path="/m/register" element={<MRegister />} />
          <Route path="/m/password" element={<MPassword />} />
          <Route path="/m/logout" element={<MLogout />} />
          <Route path="/" element={<MRoot />}>
            <Route index element={<Navigate to="/m/orders" />} />
            <Route path="/m/orders" element={<MOrders />} />
            <Route path="/m/orders/:orderId" element={<MOrder />} />
            <Route path="/m/reports/:orderId" element={<MReport />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;