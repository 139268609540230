import { Form, Input, Button, Space, message, Result } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./Password.module.css";

import { STATUS } from "../constants";
import { _post } from "../utils";
import { useState } from "react";

// 注册页面
function PagePasswordReset() {
  const navigate = useNavigate();

  // 状态
  const [status, setStatus] = useState(STATUS.idle);
  // phone 的值
  const [userPhone, setUserPhone] = useState(null);
  // 验证码发送标识
  let [sendMessageIndex, setSendMessageIndex] = useState(true);
  // 定义倒计时最大时长
  // let maxTime = 60
  let [maxTime, setMaxTime] = useState(60);

  // 提交注册逻辑的主体
  const onFinish = (values) => {
    // 发送注册请求
    const doPost = async () => {
      try {
        setStatus(STATUS.loading);
        await _post("/resetpassword", values);
        setStatus(STATUS.success);
      } catch (err) {
        if (err.response.status === 403) {
          message.info(err.response.data.message);
        }
        setStatus(STATUS.failure);
      }
    };
    if (values.password !== values.confirmPassword) {
      message.info("两次密码输入不相同，请重新输入");
    } else {
      Reflect.deleteProperty(values, "confirmPassword");
      doPost();
    }
  };
  // 存手机号码
  const savePhone = (e) => {
    setUserPhone(e.target.value);
  };
  // 发送验证码的请求逻辑
  const sendMessage = async () => {
    setSendMessageIndex(false);
    sendMessageTimer();
    const regPhone = new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g");
    if (regPhone.test(userPhone)) {
      await _post("/sms", { username: userPhone });
    } else {
      message.info("手机号错误");
    }
  };
  // 注册成功后的去登陆页面
  const successResult = () => {
    return (
      <Result
        status="success"
        title="重置密码成功!"
        subTitle="前往登陆页面进行登陆."
        extra={[
          <Button
            size="large"
            type="primary"
            key="console"
            onClick={() => navigate("/m/login")}
          >
            Go Login
          </Button>,
        ]}
      />
    );
  };
  // 倒计时
  const sendMessageTimer = () => {
    let messageTimer = setInterval(() => {
      if (maxTime > 0) {
        // --maxTime
        setMaxTime(--maxTime);
      } else {
        clearInterval(messageTimer);
        setSendMessageIndex(true);
        setMaxTime(60);
      }
    }, 1000);
  };

  return (
    <div className={styles.root}>
      {status === STATUS.success ? (
        successResult()
      ) : (
        <>
          <div className={styles.header}>
            <img src="/logo2.png" alt="logo" />
            <div className={styles.title}>忘记密码</div>
          </div>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="手机"
              name="username"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g"),
                  message: "手机号格式错误",
                },
              ]}
            >
              <Input
                size="large"
                onChange={(e) => savePhone(e)}
                placeholder="手机号码"
              />
            </Form.Item>
            <Form.Item label="短信验证码">
              <div className={styles.row}>
                <Form.Item
                  name="message"
                  rules={[{ required: true, message: "请输入验证码" }]}
                >
                  <Input size="large" placeholder="短信验证码" />
                </Form.Item>
                {sendMessageIndex ? (
                  <Button
                    size="large"
                    type="primary"
                    style={{ width: "8rem" }}
                    onClick={sendMessage}
                  >
                    发送验证码
                  </Button>
                ) : (
                  <Button
                    size="large"
                    disabled
                    type="primary"
                    style={{ width: "8rem" }}
                  >
                    重新发送{maxTime}
                  </Button>
                )}
              </div>
            </Form.Item>
            <div className={styles.divider} />
            <Form.Item
              label="登录密码"
              name="password"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(
                    /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/,
                    "g"
                  ),
                  message:
                    "密码格式错误，最少6位，包括至少1个大写字母，1个小写字母，1个数字",
                },
              ]}
            >
              <Input.Password size="large" placeholder="登录密码" />
            </Form.Item>
            <Form.Item
              label="确认登录密码"
              name="confirmPassword"
              rules={[{ required: true, message: "请再次输入密码" }]}
            >
              <Input.Password size="large" placeholder="再输入一次登录密码" />
            </Form.Item>
            <Form.Item>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Button size="large" htmlType="submit" type="primary" block>
                  找回密码
                </Button>
                <Button size="large" onClick={() => navigate("/")} block>
                  直接登录
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
}
export default PagePasswordReset;
