import { Form, Input, Select, Button, message, Modal } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./Order.module.css";
import Header from "../m-components/Header";
import { ORDER_STATUS, ORDER_INDUSTRIES } from "../constants";
import { CheckOutlined } from "@ant-design/icons";
import { _post } from "../utils";
import { STATUS } from "../constants";
import { useState } from "react";
function Order() {
  const [form] = Form.useForm();
  // const [values, setValuse] = useState(null);
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [submittingStatus, setSubmittingStatus] = useState(STATUS.idle);
  const [open, setOpen] = useState(true);
  const year = new Date().getFullYear();
  const onClick = async () => {
    try {
      // await form.validateFields().then((values) => setValuse(values));
      const values = await form.validateFields();
      setSubmittingStatus(STATUS.loading);
      const normalizedValues = Object.assign({}, values, {
        finance: {
          y3: values.finance.y3.map((value) => Number(value)),
          y2: values.finance.y2.map((value) => Number(value)),
          y1: values.finance.y1.map((value) => Number(value)),
          y0: values.finance.y0.map((value) => Number(value)),
        },
        assessment: values.assessment.map((value) => Number(value)),
        assessor: values.assessor?.map((value) => Number(value)),
        status: ORDER_STATUS.success.value,
      });
      if (orderId === "new") {
        // 新建Order
        const result = await _post("/orders", normalizedValues);
        if (result.status === 200) {
          message.success("预测提交成功");
          setSubmittingStatus(STATUS.success);
          navigate(`/m/reports/${result.data.payload._id}`);
        } else {
          message.error("提交失败");
        }
      }
    } catch (err) {
      message.error(`表单校验时发现有${err.errorFields.length}项错误`);
      // console.log(err);
    }
  };
  return (
    <>
      <Header
        title="评估表"
        subTitle="商标品牌价值评估模型（试用）"
        showBackBtn
        showInfoBtn={() => setOpen(true)}
      />
      <div className={styles.root}>
        <Form layout="vertical" form={form}>
          <div className={styles.title}>基本信息</div>
          <Form.Item
            name="brand"
            label="商标"
            rules={[{ required: true, message: "请输入商标名称" }]}
          >
            <Input size="large" placeholder="商标名称" />
          </Form.Item>
          <Form.Item
            name="industry"
            label="行业"
            rules={[{ required: true, message: "请选择行业" }]}
          >
            <Select
              placeholder="选择行业"
              options={ORDER_INDUSTRIES}
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="enterprise"
            label="公司"
            rules={[{ required: true, message: "请输入公司全称" }]}
          >
            <Input size="large" placeholder="公司全称" />
          </Form.Item>
          <Form.Item
            name="creditNo"
            label="统一社会信用代码"
            rules={[{ required: true, message: "请输入统一社会信用代码" }]}
          >
            <Input size="large" placeholder="统一社会信用代码" />
          </Form.Item>
          <Form.Item
            name="contact"
            label="联系人"
            rules={[{ required: true, message: "请输入联系人姓名" }]}
          >
            <Input size="large" placeholder="联系人姓名" />
          </Form.Item>
          <Form.Item
            name="mobile"
            label="联系人手机"
            rules={[{ required: true, message: "请输入联系人手机" }]}
          >
            <Input size="large" placeholder="联系人手机号码" />
          </Form.Item>
          <Form.Item name="memo" label="备注">
            <Input.TextArea size="large" placeholder="备注" rows={5} />
          </Form.Item>
          <div className={styles.title}>{year - 3}年经营收入</div>
          <Form.Item
            name={["finance", "y3", 0]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="主营业务收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y3", 1]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="主营业务成本"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y3", 2]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="利润总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y3", 3]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="净利润"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y3", 4]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="纳税总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y3", 5]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y3", 6]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品成本"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y3", 7]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品利润总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y3", 8]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标授权收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y3", 9]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品推广投入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <div className={styles.title}>{year - 2}年经营收入</div>
          <Form.Item
            name={["finance", "y2", 0]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="主营业务收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y2", 1]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="主营业务成本"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y2", 2]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="利润总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y2", 3]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="净利润"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y2", 4]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="纳税总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y2", 5]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y2", 6]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品成本"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y2", 7]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品利润总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y2", 8]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标授权收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y2", 9]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品推广投入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <div className={styles.title}>{year - 1}年经营收入</div>
          <Form.Item
            name={["finance", "y1", 0]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="主营业务收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y1", 1]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="主营业务成本"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y1", 2]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="利润总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y1", 3]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="净利润"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y1", 4]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="纳税总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y1", 5]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y1", 6]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品成本"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y1", 7]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品利润总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y1", 8]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标授权收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y1", 9]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品推广投入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <div className={styles.title}>{year}年经营收入（预测）</div>
          <Form.Item
            name={["finance", "y0", 0]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="主营业务收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y0", 1]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="主营业务成本"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y0", 2]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="利润总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y0", 3]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="净利润"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y0", 4]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="纳税总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y0", 5]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y0", 6]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品成本"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y0", 7]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品利润总额"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y0", 8]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标授权收入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <Form.Item
            name={["finance", "y1", 9]}
            rules={[
              { required: true, message: "请输入金额" },
              { pattern: /^-?[0-9]+(\.[0-9]+)?$/, message: "请输入正确的金额" },
            ]}
            label="商标产品推广投入"
          >
            <Input size="large" placeholder="请输入" suffix="万元" />
          </Form.Item>
          <div className={styles.title}>品牌修正因素（自评）</div>
          <Form.Item
            name={["assessment", 0]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-9]|10)$/, message: "请输入正确的得分" },
            ]}
            label="1）商标品牌美誉度"
          >
            <Input size="large" placeholder="请输入" suffix="/ 10" />
          </Form.Item>
          <div className={styles.description}>
            <p>
              荣誉必须由具备公信力的机构颁发；其中国际级荣誉每个3分；国家级荣誉每个2分，最高不超过4分；地方性荣誉每个1分，最高不超过4分
            </p>
            <ul>
              <li>有国际性荣誉，得8-10分</li>
              <li>有国家级荣誉，得5-7分</li>
              <li>有地方性荣誉，得1-4分</li>
              <li>无荣誉，得0分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 1]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-9]|10)$/, message: "请输入正确的得分" },
            ]}
            label="2）商标品牌保护力度"
          >
            <Input size="large" placeholder="请输入" suffix="/ 10" />
          </Form.Item>
          <div className={styles.description}>
            <p>
              根据维权记录和相关维权措施，包括但不限于商标注册保护、商标确权、市场维护、司法诉讼、海外维权等
            </p>
            <ul>
              <li>维权力度好，得8-10分</li>
              <li>维权力度较好，得5-7分</li>
              <li>维权力度一般，得2-4分</li>
              <li>维权力度较差，得0-1分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 2]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-9]|10)$/, message: "请输入正确的得分" },
            ]}
            label="3）推广投入度"
          >
            <Input size="large" placeholder="请输入" suffix="/ 10" />
          </Form.Item>
          <div className={styles.description}>
            <p>近三年品牌推广投入</p>
            <ul>
              <li>品牌推广投入持续增长且增长率稳步提升，得9-10分</li>
              <li>品牌推广投入度持续增长，得6-8分</li>
              <li>品牌推广投入基本持平，得3-5分</li>
              <li>品牌推广投入持续减少，得1-2分</li>
              <li>无品牌推广，得0分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 3]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-8])$/, message: "请输入正确的得分" },
            ]}
            label="4）商标品牌独创性"
          >
            <Input size="large" placeholder="请输入" suffix="/ 8" />
          </Form.Item>
          <div className={styles.description}>
            <p>指与市场上商标设计类似度，是否具有独创性</p>
            <ul>
              <li>商标独创性较高，得6-8分</li>
              <li>商标独创性一般，得3-5分</li>
              <li>商标独创性较差，得0-2分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 4]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-8])$/, message: "请输入正确的得分" },
            ]}
            label="5）商标品牌美好度"
          >
            <Input size="large" placeholder="请输入" suffix="/ 8" />
          </Form.Item>
          <div className={styles.description}>
            <p>指设计的艺术性</p>
            <ul>
              <li>商标图案、文字设计美好度较高，得7-8分</li>
              <li>商标图案、文字设计美好度一般，得4-6分</li>
              <li>商标图案、文字设计美好度较低，得1-3分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 5]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-8])$/, message: "请输入正确的得分" },
            ]}
            label="6）商标许可的活跃度"
          >
            <Input size="large" placeholder="请输入" suffix="/ 8" />
          </Form.Item>
          <div className={styles.description}>
            <p>指该企业许可对象的数量是否增长、不变或降低</p>
            <ul>
              <li>活跃度较高，得6-8分</li>
              <li>活跃度一般，得3-5分</li>
              <li>活跃度较低，得0-2分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 6]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-6])$/, message: "请输入正确的得分" },
            ]}
            label="7）商标注册时长"
          >
            <Input size="large" placeholder="请输入" suffix="/ 6" />
          </Form.Item>
          <div className={styles.description}>
            <p>根据商标注册证内注册时间进行评分</p>
            <ul>
              <li>大于50年，得5-6分</li>
              <li>10-50年，得3-4分</li>
              <li>5-10年，得2-3分</li>
              <li>小于5年,得0-1分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 7]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-6])$/, message: "请输入正确的得分" },
            ]}
            label="8）商标品牌管理力度"
          >
            <Input size="large" placeholder="请输入" suffix="/ 6" />
          </Form.Item>
          <div className={styles.description}>
            <p>根据商标管理制度及落实情况进行评分</p>
            <ul>
              <li>有较完善得商标管理制度，且执行较好，得4-6分</li>
              <li>有商标管理制度，且执行一般，得1-3分</li>
              <li>无商标管理制度，得0分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 8]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-5])$/, message: "请输入正确的得分" },
            ]}
            label="9）企业管理力度"
          >
            <Input size="large" placeholder="请输入" suffix="/ 5" />
          </Form.Item>
          <div className={styles.description}>
            <p>根据相关企业管理制度及落实情况进行评分</p>
            <ul>
              <li>有企业管理体系，且执行一般，得3-4分</li>
              <li>有管理文件但未成体系，且执行一般，得1-2分</li>
              <li>无管理文件或执行较差，得0分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 9]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-5])$/, message: "请输入正确的得分" },
            ]}
            label="10）同名/相似商标的知晓度"
          >
            <Input size="large" placeholder="请输入" suffix="/ 5" />
          </Form.Item>
          <div className={styles.description}>
            <p>与被评估商标同名/相似的商标</p>
            <ul>
              <li>同名/相似商标的知晓度较高，得4-5分</li>
              <li>同名/相似商标的知晓度一般，得2-3分</li>
              <li>同名/相似商标的知晓度较低，得0-1分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 10]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-5])$/, message: "请输入正确的得分" },
            ]}
            label="11）相关类别的保护品类覆盖度"
          >
            <Input size="large" placeholder="请输入" suffix="/ 5" />
          </Form.Item>
          <div className={styles.description}>
            <p>
              根据商标注册证分类类别，结合自身经营范围，跨类别保护注册是否完善
            </p>
            <ul>
              <li>相关类别的保护品类覆盖度50%以上，得5分</li>
              <li>相关类别的保护品类覆盖度10-50%，得3-4分</li>
              <li>相关类别的保护品类覆盖度0-10%，得1-2分</li>
              <li>无相关类别的保护品类，得0分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 11]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-5])$/, message: "请输入正确的得分" },
            ]}
            label="12）商标核定使用商品/服务范围"
          >
            <Input size="large" placeholder="请输入" suffix="/ 5" />
          </Form.Item>
          <div className={styles.description}>
            <p>
              根据商标注册证对应商品、服务内容，结合自身经营范围，同类别内注册项是否完善
            </p>
            <ul>
              <li>范围较广，得5分</li>
              <li>范围一般，得3-4分</li>
              <li>范围较窄，得1-2分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 12]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-5])$/, message: "请输入正确的得分" },
            ]}
            label="13）企业生命周期"
          >
            <Input size="large" placeholder="请输入" suffix="/ 5" />
          </Form.Item>
          <div className={styles.description}>
            <p>
              原则上0-3年为初创期，3-5年为成长期，5-10年为成熟期。企业结合自身所处行业及经营状况自行判断
            </p>
            <ul>
              <li>成熟期，得5分</li>
              <li>成长期，得3-4分</li>
              <li>初创期，得1-2分</li>
              <li>衰退期，得0分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 13]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-5])$/, message: "请输入正确的得分" },
            ]}
            label="14）行业、市场地位"
          >
            <Input size="large" placeholder="请输入" suffix="/ 5" />
          </Form.Item>
          <div className={styles.description}>
            <p>如行业无排名，则根据相应市场份额进行评分</p>
            <ul>
              <li>行业排名前10名，得5分</li>
              <li>行业排名前10-50名，得3-4分</li>
              <li>行业排名前50-100名，得1-2分</li>
              <li>行业排名100名以上，得0分</li>
            </ul>
          </div>
          <Form.Item
            name={["assessment", 14]}
            rules={[
              { required: true, message: "请输入得分" },
              { pattern: /^([0-4])$/, message: "请输入正确的得分" },
            ]}
            label="15）政策支持度"
          >
            <Input size="large" placeholder="请输入" suffix="/ 4" />
          </Form.Item>
          <div className={styles.description}>
            <p>根据相关宏观、行业政策文件以及是否获得相关政策补贴进行评分</p>
            <ul>
              <li>获得宏观、行业政策支持较高，得4分</li>
              <li>获得宏观、行业政策支持一般，得2-3分</li>
              <li>获得宏观、行业政策支持较低，得1分</li>
              <li>受到宏观、行业政策限制，得0分</li>
            </ul>
          </div>
          <Form.Item>
            <Button
              size="large"
              onClick={onClick}
              disabled={submittingStatus !== STATUS.idle}
              type="primary"
              icon={<CheckOutlined />}
              block
            >
              {submittingStatus === STATUS.success ? "已完成评估" : "价值预测"}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        open={open}
        title="填表说明"
        closable={false}
        footer={
          <Button
            size="large"
            type="primary"
            onClick={() => setOpen(false)}
            block
          >
            已阅读
          </Button>
        }
        centered
      >
        <div className={styles.instruction}>
          <p className={styles.primary}>基本信息</p>
          <ul>
            <li>商标：商标名称应与商标注册证一致</li>
            <li>
              行业：根据最新版《企业绩效评价标准值》内的行业分类选择。如果企业所在行业未在列表中列出，请选择“其他”行业
            </li>
            <li>公司名称：根据营业执照填写全称</li>
            <li>统一社会信用代码：与营业执照一致</li>
            {/* <li>备注：填写公司运营、商标产品、授权许可等情况</li> */}
          </ul>
          <p className={styles.primary}>财务数据</p>
          <ul>
            <li>
              近三个完整财务年度的实际财务数据，包括公司运营财务数据和商标产品/授权许可的财务数据
            </li>
            <li>
              预测年度财务数据：最近一个完整财务年度下一年的公司运营财务预测数据和商标产品/授权许可的财务预测数据
            </li>
          </ul>
          <p className={styles.primary}>品牌修正因素（自评）</p>
          <p>请参照评价指标，对照公司实际情况，进行客观评分</p>
        </div>
      </Modal>
    </>
  );
}

export default Order;
